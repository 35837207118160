import { setupBurgerMenuListeners } from "./burger-menu";
// import { addContactFormSubmit } from "./contact";

document.addEventListener('DOMContentLoaded', async function () {
  setupBurgerMenuListeners();

  const res = await fetch('/outputs.json');
  const config = await res.json();
  addContactFormSubmit(config.ContactFormEmailSenderUrl);
});
